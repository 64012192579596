import Splide from "@splidejs/splide/dist/js/splide.js";
window.Splide = Splide;
import {Application} from "@hotwired/stimulus";
const application = Application.start();
application.debug = false;
window.Stimulus = application;
import "@splidejs/splide/css/core";
import "~/layouts/tailwind.css";
import MobileMenuController from "../controllers/mobile_menu_controller.js";
application.register("mobile-menu", MobileMenuController);
import WhatsappController from "../controllers/whatsapp_controller.js";
application.register("whatsapp", WhatsappController);
import "@fortawesome/fontawesome-free/js/fontawesome.min.js";
import "@fortawesome/fontawesome-free/js/brands.min.js";
import DropdownController from "../controllers/dropdown_controller";
application.register("dropdown", DropdownController);
import ClipboardController from "../controllers/clipboard_controller.js";
application.register("clipboard", ClipboardController);
